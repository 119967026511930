import React from 'react';
import { View } from 'react-native';

import {
  Icon,
  Text,
  space,
  useNormalize,
  useTranslate,
} from '@unboared/base-ui.all';
import { useTheme } from '@unboared/base-ui.all';

import { ShimmerPlaceholder } from '~/components/common/loading_placeholder';

export const HeroGameFeatures = ({ item, loading }: any) => {
  const { normalize } = useNormalize();
  const { translate } = useTranslate();

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: normalize(space.smaller),
      }}
    >
      <GameFeature
        loading={loading}
        icon="people"
        text={
          loading ||
          translate('screen.homePage.hero.numPlayers', {
            numPlayers: `${item.minPlayers || '?'}-${item.maxPlayers || '?'}`,
          })
        }
      />
      <GameFeature
        loading={loading}
        icon="time"
        text={loading || item.duration || 'Non défini'}
      />
    </View>
  );
};

const GameFeature = ({ icon, text, loading }: any) => {
  const { normalize } = useNormalize();
  const theme = useTheme();
  return (
    <View style={{ marginRight: normalize(space.small), flexDirection: 'row' }}>
      <ShimmerPlaceholder
        height={normalize(14)}
        shimmerStyle={{ width: normalize(14) }}
        visible={!loading}
      >
        <Icon name={icon} size={14} />
      </ShimmerPlaceholder>
      <View
        style={{
          alignItems: 'flex-start',
          justifyContent: 'center',
          marginLeft: normalize(space.tiny),
        }}
      >
        <ShimmerPlaceholder
          height={normalize(theme.sizeH4)}
          shimmerStyle={{ width: normalize(50) }}
          visible={!loading}
        >
          <Text preset="small" text={text} />
        </ShimmerPlaceholder>
      </View>
    </View>
  );
};
