import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useLanguage } from '@unboared/utils.language';
import { db } from '~/services/firebase';
import { Game } from '~/config/types';
import { useActiveUser } from '~/services/user';
import ASSETS from './assets';

export const romsAPI = {
  getScreenURL: (rom: string, emulator: string) => {
    return `https://developers.unboared.com/emulatorjs?rom=${encodeURI(
      rom,
    )}&emulator=${emulator}`;
  },

  getGamepadURL: (emulator: string) => {
    switch (emulator) {
      case 'snes':
        return 'https://developers.unboared.com/emulatorjs/gamepads/snes.html';
      case 'n64':
        return 'https://developers.unboared.com/emulatorjs/gamepads/n64.html';
      case 'atari7800':
        return 'https://developers.unboared.com/emulatorjs/gamepads/atari7800.html';
      default:
        return 'https://developers.unboared.com/emulatorjs/gamepads/snes.html';
    }
  },

  getCover: (emulator: string) => {
    switch (emulator) {
      case 'snes':
        return ASSETS.CoverSNES;
      case 'n64':
        return ASSETS.CoverN64;
      case 'atari7800':
        return ASSETS.CoverAtari7800;
      default:
        return ASSETS.CoverSNES;
    }
  },

  getIcon: (emulator: string) => {
    switch (emulator) {
      case 'snes':
        return ASSETS.IconSNES;
      case 'n64':
        return ASSETS.IconN64;
      case 'atari7800':
        return ASSETS.IconAtari7800;
      default:
        return ASSETS.IconSNES;
    }
  },

  updateGameInfos: async (uid: string, id: string, data: any) => {
    const docRef = doc(db, 'users', uid);
    try {
      let item = (await getDoc(docRef)).data();
      if (item === undefined) {
        throw Error("Les données n'ont pas pu être sauvegardées.");
      } else {
        let uploadGames = item.uploadGames;
        let gameIdx = uploadGames.findIndex((g: any) => g.id === id);
        uploadGames[gameIdx] = {
          ...uploadGames[gameIdx],
          infos: { ...uploadGames[gameIdx].infos, ...data },
        };

        updateDoc(docRef, { uploadGames: uploadGames });
      }
    } catch {
      throw Error("Les données n'ont pas pu être sauvegardées.");
    }
  },

  getGames: (uid: string, tags: Array<string> = [], lang: string = 'en') => {
    const docRef = doc(db, 'users', uid);
    const promiseGames = new Promise((resolve, reject) => {
      getDoc(docRef)
        .then((user: any) => {
          let userData = user.data();
          let gamesData: Game[] = [];
          userData.uploadGames.forEach((game: any) => {
            let data = {
              ...game.infos,
              id: game.id,
              emulator: game.emulator,
              icon: romsAPI.getIcon(game.emulator),
              cover: romsAPI.getCover(game.emulator),
              screen_url: romsAPI.getScreenURL(game.romURL, game.emulator),
              gamepad_url: romsAPI.getGamepadURL(game.emulator),
            };
            if (data.translation && data.translation[lang]) {
              data = { ...data, ...data.translation[lang] };
            }
            if (tags.length > 0) {
              if (data.tags) {
                const filteredArray = game.tags.filter((value) =>
                  tags.includes(value),
                );
                if (filteredArray.length > 0) {
                  gamesData.push(data);
                }
              }
            } else {
              gamesData.push(data);
            }
          });

          resolve(gamesData);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
    return promiseGames;
  },

  getGame: (uid: string, id: string, lang: string = 'en') => {
    const docRef = doc(db, 'users', uid);
    const promiseGame = new Promise((resolve, reject) => {
      getDoc(docRef)
        .then((user: any) => {
          let userData = user.data();
          let data;
          userData.uploadGames
            .filter((game: any) => game.id === id)
            .map((game: any) => {
              data = {
                ...game.infos,
                id: game.id,
                emulator: game.emulator,
                icon: romsAPI.getIcon(game.emulator),
                cover: romsAPI.getCover(game.emulator),
                screen_url: romsAPI.getScreenURL(game.romURL, game.emulator),
                gamepad_url: romsAPI.getGamepadURL(game.emulator),
              };
              if (data.translation && data.translation[lang]) {
                data = { ...data, ...data.translation[lang] };
              }
            });
          if (data) {
            resolve(data);
          } else {
            reject(`${id} is not a rom id`);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
    return promiseGame;
  },
};

export const useRom = (id: string) => {
  const { language } = useLanguage();
  const [game, setGame] = useState<any>();
  const { user } = useActiveUser();

  useEffect(() => {
    const loadGame = async (id: string) => {
      romsAPI
        .getGame(user.uid, id, language)
        .then(setGame)
        .catch((error) => {
          console.log(error);
        });
    };
    loadGame(id);
  }, [id, language]);

  return game;
};

export const useRoms = (tags = []) => {
  const { language } = useLanguage();
  const [items, setItems] = useState([]);
  const { user } = useActiveUser();

  useEffect(() => {
    if (user?.uid) {
      const docRef = doc(db, 'users', user.uid);
      const unsub = onSnapshot(docRef, (doc) => {
        try {
          (async () => {
            romsAPI
              .getGames(user.uid, tags, language)
              .then((games) => {
                setItems(games.reverse());
              })
              .catch((error) => {
                console.log(error);
              });
          })();
        } catch (error) {
          console.log(error);
        }
      });
      return unsub;
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const loadGames = async () => {
        romsAPI
          .getGames(user.uid, tags, language)
          .then((games) => {
            setItems(games.reverse());
          })
          .catch((error) => {
            console.log(error);
          });
      };
      loadGames();
    }
  }, [user, language]);

  return items;
};
