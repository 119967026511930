import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';

import { Video, base_color, useTheme } from '@unboared/base-ui.all';
import { useMusicManager } from '@unboared/utils.sound';

import { isDevMode } from '~/config/env';
import { LaunchPage } from '~/screens/screen/launch';
import { useLaunchSystem } from './useLaunchSystem';
import { useConnexionInfos } from '~/utils/connexion_infos';

const SplashScreen = require('../../../../assets/video/SplashScreen.mp4');

export const OverAll = ({ children }: any) => {
  const theme = useTheme();
  return (
    <View
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: theme.backgroundColor,
        zIndex: 10000,
      }}
    >
      {children}
    </View>
  );
};

export const LaunchSystemPopup = () => {
  const { onPlay } = useMusicManager();
  const { toggleShowConnexionInfos } = useConnexionInfos();
  const { interacted, launched, interact, launch } = useLaunchSystem();

  if (!isDevMode()) {
    const launchUnboaredHome = () => {
      toggleShowConnexionInfos();
      launch();
      onPlay();
    };

    const launchFullscreenAndSplash = () => {
      interact();
      // if (Platform.OS == 'web') {
      //   toggleFullscreen();
      // }
    };

    if (!interacted) {
      return (
        <OverAll>
          <LaunchPage onLaunch={launchFullscreenAndSplash} />
        </OverAll>
      );
    }

    if (!launched) {
      return (
        <OverAll>
          <Video
            style={{
              position: 'fixed',
              right: 0,
              top: 0,
              width: '100%',
              height: '100%',
              backgroundColor: base_color.dark,
            }}
            source={SplashScreen}
            loop={false}
            onEnd={launchUnboaredHome}
          />
        </OverAll>
      );
    }
  }

  return null;
};
