import React from 'react';
import { Platform, View } from 'react-native';
import {
  Text,
  Theme,
  base_color,
  unboaredTheme,
  useNormalize,
  useTheme,
} from '@unboared/base-ui.all';
import { useMargin } from '../signin';

export const CentralSeparator = () => {
  const { normalize } = useNormalize();
  const theme = useTheme();
  if (Platform.OS !== 'web') {
    return <></>;
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <View style={style}>
      <Separator
        style={{ fontSize: normalize(theme.sizeH2), color: 'white' }}
      />
    </View>
  );
};

export const Separator = ({ style }: any) => {
  const { normalize } = useNormalize();
  const { smallMargin } = useMargin();

  return (
    <Text
      tx="common.signin.or"
      style={[
        {
          ...smallMargin,
          fontWeight:'bold',
          alignSelf: 'center',
          color:base_color.light_tertiary
        },
        style,
      ]}
    />
  );
};
