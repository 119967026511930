import React, { useEffect } from 'react';
import { Platform, Pressable, View, ViewStyle } from 'react-native';
import { useLinkTo } from '~/navigation/router';

import {
  useNormalize,
  useTranslate,
  accent_color,
  Text,
  Heading,
  space,
} from '@unboared/base-ui.all';

import { useMargin } from './hooks/useMargin';
import { log } from '~/config/env';
import { GoogleSignIn } from './components/google';
import { GuestSignIn } from './components/guest';
import { useAuth } from '~/services/auth';
import { ActivityIndicator } from '~/components/common/loaders';
import { Separator } from '../separator';
import { useAnalyticsAccessEvent } from '~/services/firebase';
import { EmailPasswordButton } from './components/email_password_button';

/**
 * Sign in page.
 */
export const SignIn = () => {
  log.debug('Mount SignIn');
  useAnalyticsAccessEvent('platform/signin')
  
  const { normalize } = useNormalize();
  const {mediumMargin} = useMargin()
  const loading = useAuth((state) => state.loading);
  const globalAuth = useAuth();
  


  const styles = {
    container: {
      flexDirection: 'row',
    } as ViewStyle,
    flexCenter: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    } as ViewStyle,
    content: {
      width: normalize(250),
      justifyContent: 'center',
      textAlign:'center'
    } as ViewStyle,
  };

  return (
    <View style={[styles.flexCenter, styles.container]}>
      <View style={styles.flexCenter}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View style={styles.content}>
            <Heading
              type="h2"
              style={{ alignSelf: 'center' }}
              tx="common.signup.title"
            />
            <Text
              style={{ marginBottom: normalize(space.small), fontWeight: 300 }}
              tx="common.signup.description"
            />
            <GuestSignIn />
            <View style={mediumMargin}>
              <Separator />
            </View>
            <GoogleSignIn />
            <EmailPasswordButton />
            <CreateAccountLink />
          </View>
        )}
      </View>
    </View>
  );
};

/**
 * The link to the signup page.
 */
const CreateAccountLink = () => {
  const { translate } = useTranslate();
  const { largeMargin } = useMargin();

  const linkTo = useLinkTo();
  const resetFields = useAuth((state) => state.resetFields);

  return (
    <Pressable
      onPress={() => {
        resetFields();
        linkTo('/signup');
      }}
    >
      <Text
        style={{
          ...largeMargin,
          fontWeight:'bold',
          alignSelf: 'center',
        }}
      >
        {translate('common.signin.noAccount.message')}{' '}
        <Text
          style={{
            color: accent_color.important,
          }}
          tx="common.signin.noAccount.createAccount"
        />
      </Text>
    </Pressable>
  );
};

export default SignIn;
