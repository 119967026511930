import React, { useEffect } from 'react';
import { Platform, View } from 'react-native';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '~/services/auth';
import {
  initialUser,
  useActiveUser,
  useActiveUserManager,
} from '~/services/user';
import { UserAuthentification } from '~/components/common/view/profile_setting';

/* Import screens for the screen flow  */
import { UnboaredScreenApp } from '~/screen_app';

/* Import screens for the gamepad flow  */
import { UnboaredGamepadApp } from '~/gamepad_app';
import { AuthContainer } from './AuthContainer';
import { ActivityIndicator } from '~/components/common/loaders';
import { log } from '~/config/env';
import { Route, Router, Routes } from '~/navigation/router';
import { useLocationMemo } from './locationMemo';
import { AccessPage } from '~/screens/gamepad/access';

/**
 * Main flow. This flow contains one application for the screen and
 * one for the gamepad.
 */
const MainRouter = () => {
  // Update the user data according to the authentified user
  useActiveUserManager();

  return (
    <>
      <Router>
        <MainRoutes />
      </Router>
    </>
  );
};

const MainRoutes = () => {
  
  // If a target location was stored, navigate to this location
  const navigate = useNavigate();
  const { location, clearLocation } = useLocationMemo();
  useEffect(() => {
    if (location) {
      const path = `${location?.pathname}${location?.search}`;
      clearLocation();
      navigate(path);
    }
  }, []);

  useEffect(() => {
    if (Platform.OS == 'web') {
      document.title = 'Unboared';
    }
  }, [location]);

  return (
    <>
      <UserSelectionAndLaunch />
      <Routes>
        <Route path="/gamepad" element={<AccessPage />} />
        <Route path="/gamepad/:roomID" element={<UnboaredGamepadApp />} />
        <Route path="*" element={<UnboaredScreenApp />} />
      </Routes>
    </>
  );
};

const UserSelectionAndLaunch = () => {
  log.debug('In App [Load user]');

  const { auth, initialLoading } = useAuth();
  const { user, createUser, loading, setDefaultUser } = useActiveUser();
  const launchUI = (newPlayer: any) => {
    createUser(auth.uid, newPlayer);
  };

  useEffect(() => {
    if (auth && !user && !auth.isLogin) {
      setDefaultUser();
    }
  }, [auth, user]);

  if (initialLoading) {
    return (
      <View
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 100,
          elevation: 100,
          backgroundColor: '#070219',
        }}
      >
        <AuthContainer>
          <ActivityIndicator />
        </AuthContainer>
      </View>
    );
  }

  return (
    <>
      {auth && !user && auth.isLogin && (
        <View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 100,
            elevation: 100,
            backgroundColor: '#070219',
          }}
        >
          <AuthContainer>
            {loading ? (
              <ActivityIndicator />
            ) : (
              <UserAuthentification player={initialUser} onSubmit={launchUI} />
            )}
          </AuthContainer>
        </View>
      )}
    </>
  );
};

export default MainRouter;
