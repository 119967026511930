import React from 'react';
import { Text, StyleSheet, View, Pressable } from 'react-native';

// import { useHistory } from 'react-router';
// import { Link } from 'react-router-dom';
import { Logo } from '@unboared/design.all';
import { SystemActionsWidget } from '~/components/screen/system_actions';
import { useListener } from '@unboared/lib';
import { useLinkTo } from '~/navigation/router';
import { useNormalize } from '@unboared/base-ui.responsive.sizes';
import { space } from '@unboared/base-ui.theme.spacing';

export type InGameProps = {
  /**
   * a text to be rendered in the component.
   */
  text: string
};


export const InGameLayout = ({ children }: { children: any }) => {
  
  const linkTo = useLinkTo()
  
  const goToHome = () => {
    linkTo('/')
  }
  
  useListener("pressUnboaredGames", goToHome);

  const { normalize } = useNormalize()

  const padding = normalize(space.small)
  const upperLeftCorner = {
    top: padding,
    left: padding,
  }

  const bottomLeftCorner = {
    bottom: padding,
    left: padding,
  }


  const bottomRightCorner = {
    bottom: padding,
    right: padding,
  }


  return (
    <View style={{ flex: 1 }}>
      <View style={[styles.over, upperLeftCorner]}>
        <Pressable onPress={goToHome}>
          <Logo size={30} />
        </Pressable>
      </View>
      <View style={[styles.over, bottomRightCorner]}>
        <SystemActionsWidget size={24} />
      </View>
      <View style={{ flex: 1 }}>
        {children}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  over: {
    position: 'absolute',
    zIndex: 9,
    elevation: 9,
  }
})
