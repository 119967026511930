import { createPersistStorage } from '@unboared/utils.storage';

export const RESTRICT_ACCESS = true;
export const ACCESS_TOKEN = 'WeLoveGame';

interface tokenState {
  token: string;
  setToken: (newToken: string) => void;
}

export const useToken = createPersistStorage<tokenState>(
  '_token',
  (set, get) => ({
    token: '',
    setToken: (newToken: string) => {
      set({ token: newToken })
    },
  })
)

export const useAccessVerification = () => {
  const token = useToken(state => state.token)
  return (!RESTRICT_ACCESS || (token === ACCESS_TOKEN))
}