import React from 'react';
import { Button, space, useNormalize } from '@unboared/base-ui.all';
import { View } from 'react-native';

export const UploadButton = ({
  uploadTask,
  uploadStatus,
  disable,
  handleSubmit,
  onClose,
}: any) => {
  if (uploadStatus !== undefined) {
    if (uploadStatus.status === 'running') {
      return (
        <UploadingContainer uploadTask={uploadTask}>
          <Button
            style={{ flex: 1 }}
            text={`(${uploadStatus.progress}%) Téléchargement en cours ...`}
            disabled={true}
          />
        </UploadingContainer>
      );
    }
    if (uploadStatus.status === 'paused') {
      return (
        <UploadingContainer uploadTask={uploadTask} paused>
          <Button
            style={{ flex: 1 }}
            text={`(${uploadStatus.progress}%) Téléchargement en pause ...`}
            disabled={true}
          />
        </UploadingContainer>
      );
    }
  } else {
    return (
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <Button preset="secondary" text="Annuler" onPress={onClose} />
        ;
        <Button
          style={{ flex: 1 }}
          text="Importer"
          onPress={handleSubmit}
          disabled={disable}
        />
        ;
      </View>
    );
  }
};

const UploadingContainer = ({ uploadTask, paused, children }: any) => {
  const { normalize } = useNormalize();
  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      {children}
      <Button
        style={{ marginHorizontal: normalize(space.smaller) }}
        preset="secondary"
        icon={paused ? 'play' : 'pause'}
        onPress={
          paused
            ? () => uploadTask.current.resume()
            : () => uploadTask.current.pause()
        }
      />
      <Button
        preset="secondary"
        icon="md-cancel"
        onPress={() => uploadTask.current.cancel()}
      />
    </View>
  );
};
