import React from 'react';

import { Button } from '@unboared/base-ui.all';
import { EditRom } from './edit_rom';
import { useGlobalPopup } from '~/components/screen/popup/global_popup';

export const EditRomButton = ({ romInfos, style }: any) => {
  const { open, close } = useGlobalPopup();

  return (
    <Button
      style={style}
      preset="secondary"
      icon="edit"
      textPreset="medium"
      tx="screen.homePage.hero.edit"
      onPress={() => open(<EditRom romInfos={romInfos} onClose={close} />)}
    />
  );
};
