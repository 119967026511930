/* Declare environment variables */
// import { MODE, ACCESS_VERIFICATION, DEV_SOCKET_SERVER, TEST_SOCKET_SERVER, PROD_SOCKET_SERVER } from '@env'

let MODE = 'prod';
export const URL_TEST_GAME_SCREEN =
  MODE === 'dev' && '' && 'http://192.168.1.95:19007/';
export const URL_TEST_GAME_GAMEPAD =
  MODE === 'dev' && '' && 'http://192.168.1.95:19007/gamepad';

const ACCESS_VERIFICATION = true;
const DEV_SOCKET_SERVER = 'https://socket-server-v03.herokuapp.com'; //"http://192.168.1.95:8000"
const TEST_SOCKET_SERVER = 'https://socket-server-v03.herokuapp.com';
const PROD_SOCKET_SERVER = 'https://socket-server-v03.herokuapp.com';

export const ENV = {
  MODE,
  ACCESS_VERIFICATION,
  DEV_SOCKET_SERVER,
  TEST_SOCKET_SERVER,
  PROD_SOCKET_SERVER,
};

export const isProdMode = (): boolean => MODE === 'prod';
export const isDevMode = (): boolean => MODE === 'dev';
export const isTestMode = (): boolean => MODE === 'test';

export const debug = (value: any): void => {
  if (isDevMode()) {
    console.log(`[DEBUG] ${value}`);
  }
};

export const info = (value: any): void => {
  console.log(`[INFO] ${value}`);
};

export const warning = (value: any): void => {
  console.error(`[WARNING] ${value}`);
};

export const error = (value: any): void => {
  console.error(value);
};

export const printEnv = () => {
  let mode = MODE;
  console.log(`
    NODE_ENV=${mode}
    DEV_SOCKET_SERVER=${DEV_SOCKET_SERVER}
    TEST_SOCKET_SERVER=${TEST_SOCKET_SERVER}
    PROD_SOCKET_SERVER=${PROD_SOCKET_SERVER}
    ACCESS_VERIFICATION=${ACCESS_VERIFICATION}
  `);
};

export const log = {
  debug: debug,
  info: info,
  error: error,
};
