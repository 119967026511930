import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTheme } from '@unboared/utils.theme';

import { api, useCustomDeviceStateProperty, usePlayers } from '@unboared/lib';
import { GameRunner } from '~/components/screen/view/game_runner';
import { UnboaredPageLoader } from '~/components/common/loaders';
import { log, URL_TEST_GAME_GAMEPAD } from '~/config/env';

export const GameGamepad = ({ game }: { game: any }) => {
  if (!game) {
    return <UnboaredPageLoader infos="Loading game" />;
  }

  return (
    <GameRunner
      title={game.id || 'Game'}
      src={game.gamepad_url}
      version={game.ub_version || '0.3'}
    />
  );
};

const useGameID = () => {
  const [gameID, setGameID] = useState();

  useEffect(() => {
    function loadCurrGame() {
      const id = api().getCustomDeviceStateProperty(
        api().getHostID(),
        'gameID',
      );
      setGameID(id);
    }
    loadCurrGame();
  }, []);
  return gameID;
};

const useCurrentGame = () => {
  const { getHostID } = usePlayers();
  const { getCustomDeviceStateProperty } = useCustomDeviceStateProperty();

  return getCustomDeviceStateProperty(getHostID(), 'currentGame');
};

export const GamepadInGamePage = () => {
  log.debug('In Game');

  const theme = useTheme((state) => state.theme);
  const game = useCurrentGame();
  console.log(game)

  if (!game) {
    return <UnboaredPageLoader infos="Loading game" />;
  }

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.backgroundColor,
      }}
    >
      <GameGamepad game={game} />
    </View>
  );
};
