import { Button, Heading, space, useNormalize } from "@unboared/base-ui.all"
import { useAuth } from "~/services/auth"
import { ActivityIndicator } from "~/components/common/loaders"
import { View } from "react-native"
import { useGlobalPopup } from "~/components/screen/popup/global_popup"

export const LogoutConfirmation = () => {
    const loading = useAuth(state => state.loading)
    const logout = useAuth(state => state.logout)
    const close = useGlobalPopup(state => state.close)
    const { normalize } = useNormalize()

    if (loading) {
        return <ActivityIndicator />
    }

    return (
        <>
            <Heading type='h2' tx="screen.settingPage.myAccount.action.logout.popup.title" />
            <View style={{ marginTop: normalize(space.smaller), flexDirection: 'row', justifyContent: 'space-around' }}>
                <Button style={{ marginHorizontal: normalize(space.smaller) }} tx="screen.settingPage.myAccount.action.logout.popup.validate" onPress={() => {
                    close()
                    logout()
                }} />
                <Button style={{ marginHorizontal: normalize(space.smaller) }} tx="screen.settingPage.myAccount.action.logout.popup.cancel" onPress={close} />
            </View>
        </>
    )
}
