import React, { useEffect } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { printEnv } from '~/config/env';
import { useAnalyticsAccessEvent } from '~/services/firebase';
import { getBrowserName, getOS } from '~/utils/detect_browser';
import MainApp from './src/app';
import N64App from './src/n64_app';

function App() {
  printEnv();
  useAnalyticsAccessEvent(`browser/${getBrowserName()}`)
  useAnalyticsAccessEvent(`OS/${getOS()}`)

  return (
    <SafeAreaProvider>
      <MainApp />
    </SafeAreaProvider>
  );
}

export default App;
