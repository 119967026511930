import React from 'react';

import env, { isDevMode, isProdMode, isTestMode } from '~/config/env';
import { ENV } from '~/config/env';

export const ACCESS_VERIFICATION = ENV.ACCESS_VERIFICATION || false;

export const PROD_WSS = ENV.PROD_SOCKET_SERVER;
export const TEST_WSS = ENV.TEST_SOCKET_SERVER || PROD_WSS;
export const DEV_WSS = ENV.DEV_SOCKET_SERVER || TEST_WSS || PROD_WSS;

export const getSocketServer = () => {
  if (isProdMode()) {
    return PROD_WSS;
  } else if (isDevMode()) {
    return DEV_WSS;
  } else if (isTestMode()) {
    return TEST_WSS;
  } else {
    env.error('Unknown environement mode');
  }
};

// General configs
export const GAME_NAME = 'platform';
export const DEFAULT_SCENE = 'home';

export const RESTRICT_ACCESS = true;
export const SHOW_CONNEXION_INFOS_ON_OPEN = false;
export const ACCESS_TOKEN = 'WeLoveRetro';

// Version des visuels
export const VISUAL_CONNEXION_INFOS_GAMEPAD: boolean = false; //
export const VISUAL_CONNEXION_INFOS_SCREEN: string = 'Video'; // choose between: "Modal", "Blocks" or "Video"

// Assets
export const PUBLIC_ASSETS =
  'https://raw.githubusercontent.com/unboared/public-assets/main/';
export const CGU =
  'https://raw.githubusercontent.com/unboared/public-assets/main/pdf/CGU-PlayUnboared.pdf';

export const UNBOARED_DEMO_VIDEO_URL = "https://developers.unboared.com/public-assets/video/Unboared_DemoVideo.mp4";

export const PLAY_UNBOARED_DOMAIN = "play.unboared.com";

export const ASSETS = {
  backgroundGames: require('./assets/images/backgroundGames.png'),
};

// Endpoints
export const GAME_ENDPOINT = '/game/';
export const ROOM_ENDPOINT = '/gamepad/';

export const getGameURL = (host: string, game: string) => {
  return host + GAME_ENDPOINT + game;
};

export const getRoomURL = (host: string, room: number | string) => {
  return host + ROOM_ENDPOINT + room;
};

export function makeid(length: number) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const getAdventurerURL = (seed: string) => {
  return `https://api.dicebear.com/5.x/adventurer/png?seed=${seed}&backgroundColor=transparent`;
};

export const sampleAvatar = (): string => {
  const seed = makeid(8);
  return getAdventurerURL(seed);
};

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: 'AIzaSyCMiw0ST6sRUzcGCjltGE_q36gcxl6XF5k',

  authDomain: 'unboared.firebaseapp.com',

  projectId: 'unboared',

  storageBucket: 'unboared.appspot.com',

  messagingSenderId: '803182974472',

  appId: '1:803182974472:web:8a1f208c65da07792763bd',

  measurementId: 'G-82N796KB49',

};
