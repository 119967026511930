
export const EMULATORS = ['n64', 'snes', 'atari7800'];

export function getEmulator(ext: string) {
  if (['fds', 'nes', 'unif', 'unf'].includes(ext)) return 'nes';

  if (['smc', 'fig', 'sfc', 'gd3', 'gd7', 'dx2', 'bsx', 'swc'].includes(ext))
    return 'snes';

  if (['z64', 'n64'].includes(ext)) return 'n64';

  if (['nds', 'gba', 'gb', 'z64', 'n64'].includes(ext)) return ext;

  if (['a78'].includes(ext)) {
    return 'atari7800';
  }

  return undefined;
}