import React, { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { Player } from '@unboared/native.types';
import { sampleAvatar } from '~/config/const';
import {
  useTranslate,
  useTheme,
  space,
  Theme,
  useNormalize,
  Text,
  Icon,
} from '@unboared/base-ui.all';
import {
  Heading,
  TextInput,
  Button,
  PortraitMobileLayout,
} from '@unboared/base-ui.all';
import { AvatarSelection } from '@unboared/design.all';
import { useAuth } from '~/services/auth';

export type BaseProfileSettingProps = {
  player: Player;
  submitTxt: string;
  labelTxt: string;
  onSubmitted?: (player: Player) => void;
  onClose?: () => void;
  create?: boolean;
};

const BaseProfileSetting = ({
  player,
  submitTxt,
  labelTxt,
  onSubmitted,
  onClose,
  create,
}: BaseProfileSettingProps) => {
  const theme = useTheme() as Theme;
  const { normalize } = useNormalize();
  const { translate } = useTranslate();
  const [localAvatar, setLocalAvatar] = useState(player.avatar);
  const [localUsername, setLocalUsername] = useState(player.username);
  const { logout } = useAuth();

  const handleSubmit = () => {
    if (onSubmitted) {
      onSubmitted({ ...player, username: localUsername, avatar: localAvatar });
    }
  };

  const input_styles = [styles.input, { marginBottom: normalize(space.small) }];

  return (
    <View style={{ flex: 1, alignSelf: 'stretch' }}>
      <PortraitMobileLayout
        header={<Heading>{translate('gamepad.profileSetting.title')}</Heading>}
        flex={{ header: 20, content: 80 }}
      >
        <View style={input_styles}>
          <Heading type="h3" style={{ margin: normalize(space.smaller) }}>
            {translate('gamepad.profileSetting.avatarLabel')}
          </Heading>
          <AvatarSelection
            current={localAvatar}
            setAvatar={setLocalAvatar}
            color={player.color}
            generator={sampleAvatar}
            size={80}
          />
        </View>
        <View style={input_styles}>
          <Heading type="h3" style={{ margin: normalize(space.smaller) }}>
            {labelTxt}
          </Heading>
          <TextInput
            minLength={3}
            maxLength={12}
            text={localUsername}
            placeholder={translate(
              'gamepad.profileSetting.usernamePlaceholder',
            )}
            onChangeText={setLocalUsername}
          />
        </View>
        <View style={[input_styles, styles.buttons]}>
          {onClose && (
            <Button preset="secondary" icon="back" onPress={onClose} />
          )}
          <Button
            preset="primary"
            text={submitTxt}
            onPress={handleSubmit}
            style={{ flex: 0.8 }}
          />
        </View>
        {!create && (
          <Pressable
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
            onPress={logout}
          >
            <Icon name="md-logout" size={15} color={theme.color.primary} />
            <Text
              style={{
                color: theme.color.primary,
                marginVertical: normalize(space.small),
              }}
              tx="screen.settingPage.myAccount.action.logout.title"
            />
          </Pressable>
        )}
      </PortraitMobileLayout>
    </View>
  );
};

export const UserAuthentification = ({
  player,
  onSubmit,
}: {
  player: Player;
  onSubmit: (player: Player) => void;
}) => {
  const randomAvatar = sampleAvatar();
  const { translate } = useTranslate();

  return (
    <BaseProfileSetting
      player={{ ...player, avatar: randomAvatar }}
      labelTxt={translate('gamepad.profileSetting.create.usernameLabel')}
      submitTxt={translate('gamepad.profileSetting.create.submitText')}
      onSubmitted={onSubmit}
      create
    />
  );
};

type ProfileSettingProps = {
  player: Player;
  onSubmit: (player: Player) => void;
  onClose: () => void;
};

export const ProfileSetting = ({
  player,
  onSubmit,
  onClose,
}: ProfileSettingProps) => {
  const { translate } = useTranslate();

  return (
    <BaseProfileSetting
      player={player}
      labelTxt={translate('gamepad.profileSetting.change.usernameLabel')}
      submitTxt={translate('gamepad.profileSetting.change.submitText')}
      onSubmitted={onSubmit}
      onClose={onClose}
    />
  );
};

const styles = StyleSheet.create({
  input: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttons: {
    flexDirection: 'row',
    width: '80%',
    justifyContent: 'space-evenly',
  },
});
