import { log } from '~/config/env';
import { db as firebaseDB } from '~/services/firebase';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { User } from '~/config/types/user';

export const usersAPI = {
  /**
   * Get the user from its id.
   *
   * @param uid the user identifier
   * @returns
   */
  getUser: (uid: string) => {
    const docRef = doc(firebaseDB, 'users', uid);
    const promiseUser = new Promise((resolve, reject) => {
      getDoc(docRef)
        .then((item: any) => {
          let data = item.data();
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
    return promiseUser;
  },

  createUser: (user: any) => {
    const docRef = doc(firebaseDB, 'users', user.uid);
    const promiseUser = new Promise((resolve, reject) => {
      setDoc(docRef, user)
        .then((item: any) => {
          resolve(item);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
    return promiseUser;
  },

  updateProfile: async (uid: string, avatar: string, username: string) => {
    const docRef = doc(firebaseDB, 'users', uid);
    updateDoc(docRef, {
      avatar_url: avatar,
      username: username,
    })
      .then(() => {
        console.log('[SUCCESS] Success to update profile settings');
      })
      .catch(() => {
        console.error('[FAIL] Failed to update profile settings');
      });
  },

  addToFavorites: async (
    uid: string,
    currentFavorites: any,
    recipeID: string,
  ) => {
    const docRef = doc(firebaseDB, 'users', uid);
    const newRef = doc(firebaseDB, 'recipes/' + recipeID);

    const promiseUser = new Promise((resolve, reject) => {
      updateDoc(docRef, {
        favorites: [...currentFavorites, newRef],
      })
        .then((item: any) => {
          resolve(item);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
    return promiseUser;
  },

  removeToFavorites: async (
    uid: string,
    currentFavorites: any,
    recipeID: any,
  ) => {
    const docRef = doc(firebaseDB, 'utilisateurs', uid);

    const newFav = currentFavorites.filter((fav) => fav.id !== recipeID);

    const promiseUser = new Promise((resolve, reject) => {
      updateDoc(docRef, {
        favorites: newFav,
      })
        .then((item: any) => {
          resolve(item);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
    return promiseUser;
  },
};

export const useUser = (id: string) => {
  const [user, setUser] = useState<User>();
  useEffect(() => {
    const loadUser = async (id: string) => {
      if (id) {
        usersAPI
          .getUser(id)
          .then(setUser)
          .catch((error) => {
            console.log(error);
          });
      }
    };
    loadUser(id);
  }, [id]);

  return user;
};
