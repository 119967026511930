import { GameRunnerType, GameRunnerVersion } from './types'
import { GameRunner as GameRunner0_1_0 } from './v0.1.0/game_runner'
import { GameRunner as GameRunner0_3_0 } from './v0.3.0/game_runner'

export const GameRunnerMap: Map<GameRunnerVersion, GameRunnerType> = new Map([
  ['0.1', GameRunner0_1_0],
  ['0.3', GameRunner0_3_0],
])

export const GameRunnerList = [...GameRunnerMap.keys()]
