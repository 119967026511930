import React from 'react';

import {
  Button,
  base_color,
  transparency,
  useNormalize,
} from '@unboared/base-ui.all';
import { UploadROM } from './upload_rom';
import { useGlobalPopup } from '~/components/screen/popup/global_popup';
import { LoginToUnlock } from './login_required';
import { useAuth } from '~/services/auth';

export const UploadRomButton = () => {
  const { normalize } = useNormalize();
  const { open, close } = useGlobalPopup();
  const { auth } = useAuth();

  return (
    <Button
      preset="secondary"
      style={{
        borderColor: transparency('dark', 0.2),
        maxHeight: normalize(25),
        maxWidth: normalize(120),
      }}
      textStyle={{
        fontSize: normalize(10),
        color: base_color.light_tertiary,
      }}
      icon="md-file-upload"
      text="Importer un jeu"
      focus
      onPress={() => open(!auth.isLogin ? <LoginToUnlock onClose={close} />: <UploadROM onClose={close} />)}
      size={8}
    />
  );
};
