import { makeid, getAdventurerURL } from '~/config/const';

/* The user type */
export type User = {
  uid: string;
  username: string;
  avatar_url: string;
  color: string;
  experience: any;
  favorites: Array<string>;
  isLogin: boolean;
};

/* The default user value */
export const initialUser = {
  uid: '',
  username: '',
  avatar_url: '',
  color: '',
  country: 'France',
  premium: false,
};

/* The default user value */
export const createDefaultUser = () => {
  const UID = makeid(4);

  return {
    uid: UID,
    username: `Guest_${UID}`,
    avatar_url: getAdventurerURL(UID),
    color: '',
    isLogin: false,
  };
};

/* The authentification state type  */
export type UserState = {
  user: User;
  loading: boolean;
  errorMessage: string;
  createUser: (uid: string, player: Player) => void;
  loadUserData: (uid: string) => void;
  setUser: (user: any) => void;
  setDefaultUser: () => void;
};

/* The default user state value */
export const initialUserState = {
  user: undefined,
  loading: true,
  errorMessage: '',
};

/**
 * The converter instance from / to player instance
 */
export const userConverter = {
  toPlayer: (user: User) => {
    return {
      uid: user.uid,
      username: user.username,
      avatar: user.avatar_url,
      color: user.color,
    };
  },
  fromPlayer: (player: Player) => {
    return {
      uid: player.uid,
      username: player.username,
      avatar_url: player.avatar,
    };
  },
  toFirestore: (user: User) => {
    return { ...user };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return { ...data };
  },
};
