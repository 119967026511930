import React from 'react';
import { AccountLockAlertDevice } from '~/components/screen/alerts/account_lock';

export const LoginToUnlock = ({ size, onClose }: any) => {
  const unlock = () => {
    window.location.reload();
    // linkTo('/signup');
  };

  return (
    <AccountLockAlertDevice
      size={size || 60}
      onCancel={onClose}
      onMoreGames={unlock}
      activeButton={0}
    />
  );
};
