import React from 'react';
import styled from 'styled-components';
import { useTheme } from 'rn-css';
import { Theme, defaultTheme } from '@unboared/base-ui.theme.themes';

export const HeroImage = ({ src, width, height, borderRadius, loading }) => {
  const theme = useTheme();
  const currentTheme = (theme as Theme) || defaultTheme;

  return (
    <StyledLinearGradient
      bg={src}
      width={width}
      height={height}
      borderRadius={borderRadius}
      bgColor={currentTheme.backgroundColor}
    />
  );
};

const StyledLinearGradient = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => `${props.borderRadius}px`};
  background-image: ${(props) => `url(${props.bg})`};
  z-index:99;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
  &::after {
    border-radius: ${(props) => `${props.borderRadius}px`};
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background-image: linear-gradient(
      to right,
      ${(props) => props.bgColor}CF 40%,
      rgba(0, 0, 0, 0)
    );
  }
`;
