import React from 'react';
import { View, Platform, Pressable } from 'react-native';

import { space, Text, useNormalize } from '@unboared/base-ui.all';
import { ConnexionInstructions, ConnexionInstructionsWithVideo, getRoomURL } from '@unboared/design.all';
import { useScreenInfos } from '@unboared/utils.orientation';

import { useSession, usePlayers } from '@unboared/lib';
import { ConnexionInfosProps } from './connexion_infos';
import { UNBOARED_DEMO_VIDEO_URL } from '~/config/const';

var host = (Platform.OS === 'web') ? window.location.protocol + '//' + window.location.host : "https://play.unboared.com/";

export const ConnexionInfosPage = ({ onClose }: ConnexionInfosProps) => {
    // Get normalize function 
    const { normalize } = useNormalize()

    // Get utils for responsiveness
    const { height, width, isPortrait } = useScreenInfos();

    // Get informations about the room (or session)
    const {getSessionID, getSessionLink} = useSession()
    const { getPlayers, getUID, getMasterID } = usePlayers();

    const ConnexionInstrComponent = /* isPortrait ? ConnexionInstructions :  */ConnexionInstructionsWithVideo

    return (
        <View style={{ width: width, height: height, flexDirection: 'row' }}>
            <ClosePopup
                style={{
                    position: "absolute",
                    zIndex:99,
                    elevation:99,
                    right: normalize(2*space.smaller),
                    top: normalize(space.smaller),
                }}
                onClose={onClose}
            />
            <ConnexionInstrComponent
                roomID={getSessionID()}
                url={getSessionLink()}
                connectedPlayers={getPlayers()}
                masterID={getUID(getMasterID())}
                urlVideo={UNBOARED_DEMO_VIDEO_URL}
            />
        </View>
    );
}


const ClosePopup = ({ style, onClose }: any) => {
    return (
        <Pressable style={style} onPress={onClose}>
            <Text preset="large" style={{ fontWeight: "bold" }} text="x" />
        </Pressable>
    )
}