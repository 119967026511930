import React from 'react';
import { ViewStyle, Pressable } from 'react-native';
import { Logo as UnboaredLogo } from '@unboared/design.all'
import { useNormalize, space } from '@unboared/base-ui.all';
import { useLinkTo } from '~/navigation/router';

export const Branding = () => {

  const linkTo = useLinkTo()

  const goToHome = () => {
    linkTo('/')
  }

  const { normalize } = useNormalize()

  const padding = normalize(space.small)
  const styles = {
    topLeft: {
      position: 'absolute',
      zIndex: 100,
      elevation: 100,
      top: padding,
      left: padding,
    } as ViewStyle
  }

  return (
    <Pressable style={styles.topLeft} onPress={goToHome}>
      <UnboaredLogo size={30} />
    </Pressable>
  )
}