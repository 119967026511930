import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy as fbOrderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useLanguage } from '@unboared/utils.language';
import { db } from '~/services/firebase';
import fuzzysort from 'fuzzysort';

export const gamesAPI = {
  getGames: (tags: Array<string> = [], lang: string = 'en') => {
    const collectionRef = collection(db, 'games');
    const promiseGames = new Promise((resolve, reject) => {
      getDocs(collectionRef)
        .then((querySnapshot) => {
          let documents: Game[] = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            if (data.translation && data.translation[lang]) {
              data = { ...data, ...data.translation[lang] };
            }
            data = { ...data, id: doc.id };
            if (tags.length > 0) {
              if (data.tags) {
                const filteredArray = data.tags.filter((value) =>
                  tags.includes(value),
                );
                if (filteredArray.length > 0) {
                  documents.push(data);
                }
              }
            } else {
              documents.push(data);
            }
          });

          resolve(documents);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promiseGames;
  },
  getGamesByName: (name: string, max = 20, lang = 'en') => {
    const collectionRef = collection(db, 'games');
    const promiseGames = new Promise((resolve, reject) => {
      getDocs(collectionRef)
        .then((querySnapshot) => {
          let documents: Game[] = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            if (!data.tags.includes('unboared')) {
              if (data.translation && data.translation[lang]) {
                data = { ...data, ...data.translation[lang] };
              }
              data = { ...data, id: doc.id };
              documents.push(data);
            }
          });

          const results = fuzzysort.go(name, documents, {
            all: true,
            key: 'title',
          });
          resolve(results.map((doc) => doc.obj));
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promiseGames;
  },
  getGame: (id: string, lang: string = 'en') => {
    const docRef = doc(db, 'games', id);
    const promiseGame = new Promise((resolve, reject) => {
      getDoc(docRef)
        .then((item: any) => {
          let data = item.data();
          if (data.translation && data.translation[lang]) {
            data = { ...data, ...data.translation[lang] };
          }
          data = { ...data, id: item.id };
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promiseGame;
  },
  renameGame: async (id: string) => {
    const docRef = doc(db, 'games', id);
    let data;
    getDoc(docRef).then((item) => {
      data = item.data();
      setDoc(doc(db, 'games', data.short), data);
    });
  },
};

export const useGame = (id: string) => {
  const { language } = useLanguage();
  const [game, setGame] = useState();

  useEffect(() => {
    const loadGame = async (id: string) => {
      gamesAPI
        .getGame(id, language)
        .then(setGame)
        .catch((error) => {
          console.log(error);
        });
    };
    loadGame(id);
  }, [id, language]);

  return game;
};

export const useGames = (tags = []) => {
  const { language } = useLanguage();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const loadGames = async () => {
      gamesAPI
        .getGames(tags, language)
        .then((games) => {
          let freeGames = [],
            freeWithAccount = [],
            commingSoon = [];
          for (let g of games) {
            switch (g.accessibility) {
              case 'free_with_account':
                freeWithAccount.push(g);
                break;
              case 'free':
                freeGames.push(g);
                break;
              case 'coming_soon':
                commingSoon.push(g);
                break;
              default:
                commingSoon.push(g);
                break;
            }
          }

          setItems([...freeGames, ...freeWithAccount, ...commingSoon]);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    loadGames();
  }, [language]);

  return items;
};

// export const ALL_GAMES = [{
//     "gameID": "unguys-sports",
//     "disable": false,
//     "title": "Unguys Sports",
//     "short": "unguys-sports",
//     "category": ["sport", "videogame"],
//     "duration": "Less than 30 minutes",
//     "minPlayers": 1,
//     "maxPlayers": 6,
//     "summary": "Find your Unguys in this multi-sport game! Train on the most popular disciplines as well as the wackiest... Then compete in a wild championship!",
//     "icon": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/unguys_sports/Cover.png",
//     "cover": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/unguys_sports/BigCover.png",
//     "screen_url": "https://unboared-unguys-sports.web.app",
//     "gamepad_url": "https://unboared-unguys-sports.web.app/gamepad",
// }, {
//     "gameID": "bomber-kitten",
//     "disable": false,
//     "title": "Bomber Kitten",
//     "short": "bomber-kitten",
//     "category": ["videogame"],
//     "duration": "10 minutes",
//     "minPlayers": 1,
//     "maxPlayers": 4,
//     "summary": "The bomber kittens are back! Collect power-ups to improve your abilities and blow up opponents/enemies to win the game. This Unboared adaptation of Bomberman offers a new gameplay experience.",
//     "icon": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/bomberkitten/CoverSmall.png",
//     "cover": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/bomberkitten/Screen.png",
//     "screen_url": "https://unboared-unblind-test.web.app",
//     "gamepad_url": "https://unboared-unblind-test.web.app/gamepad",
// },
// // {
// //     "gameID": "codenames",
// //     "disable": false,
// //     "title": "Code Names",
// //     "short": "codenames",
// //     "category": ["Board Game"],
// //     "duration": "20 minutes",
// //     "minPlayers": 4,
// //     "maxPlayers": 8,
// //     "summary": "Two rival spymasters know the secret identities of 25 agents. Their teammates know the agents only by their codenames. To win the game, your team will need to contact all of your agents in the field before the other team finds their own agents. And watch out for the assassin — meet him in the field and your team is done !",
// //     "icon": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/codenames/icon.jpg",
// //     "cover": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/codenames/cover.png",
// //     "screen_url": "https://unboared-unblind-test.web.app",
// //     "gamepad_url": "https://unboared-unblind-test.web.app/gamepad",
// // },
// {
//     "gameID": "unblind-test",
//     "disable": false,
//     "title": "Unblind Test",
//     "short": "unblind-test",
//     "category": ["Board Game"],
//     "duration": "Less than 10 minutes",
//     "minPlayers": 1,
//     "maxPlayers": 10,
//     "summary": "Get ready for a fiery music quiz... Compete with friends or family to see who is the true master of music! Choose from different playlists, and get ready to answer with your phone. Who will be the best?",
//     "icon": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/unblind-test/icon.png",
//     "screen_url": "https://unboared-unblind-test.web.app",
//     "gamepad_url": "https://unboared-unblind-test.web.app/gamepad",
// }, {
//     "gameID": "unboared-speed",
//     "disable": false,
//     "title": "Unboared Speed",
//     "short": "unboared-speed",
//     "category": ["Board Game"],
//     "duration": "Less than 20 minutes",
//     "minPlayers": 1,
//     "maxPlayers": 4,
//     "summary": "Jungle Speed is a game combining speed and observation. Put down a card and jump on the totem pole as soon as two cards are identical before your opponents. But be careful! Beware if the pictograms do not match exactly, the cards are deceptive. Keep your eyes peeled or collect the cards of other players!",
//     "icon": "https://raw.githubusercontent.com/unboared/public-assets/55484265c446c68da890fc7d12f291180427a6a1/images/games/unboared-speed/icon.png",
//     "screen_url": "https://unboared-unboared-speed.web.app",
//     "gamepad_url": "https://unboared-unboared-speed.web.app/gamepad",
// }, {
//     "gameID": "flappy-race",
//     "disable": true,
//     "title": "Flappy Race",
//     "short": "flappy-race",
//     "category": ["Board Game"],
//     "duration": "Less than 10 minutes",
//     "minPlayers": 1,
//     "maxPlayers": 6,
//     "summary": "Welcome to the Flappy Bird replica game for Unboared! The principle is very simple: compete with your friends by making the bird fly between the pipes.",
//     "icon": "https://raw.githubusercontent.com/unboared/public-assets/55484265c446c68da890fc7d12f291180427a6a1/images/games/flappy-race/icon.png",
//     "screen_url": "https://unboared-unblind-test.web.app",
//     "gamepad_url": "https://unboared-unblind-test.web.app/gamepad",
// },
// {
//     "gameID": "escape-ruins",
//     "disable": true,
//     "title": "Escape the Ruins",
//     "short": "escape-ruins",
//     "category": ["Board Game"],
//     "duration": "20 minutes",
//     "minPlayers": 2,
//     "maxPlayers": 6,
//     "summary": "You are walking through the remains of a forbidden Astheque template. All was quiet until a gust of wind blew you and your friends into a cave. A very strange cave... a tomb? ",
//     "icon": "https://cdn.siter.io/assets/ast_5RbaWsJdfEPPMRFvQQh4H8reD/d42a7217-cbac-4c8b-9165-85024910d669.jpg",
//     "screen_url": "https://unboared-unblind-test.web.app",
//     "gamepad_url": "https://unboared-unblind-test.web.app/gamepad",
// },
// {
//     "gameID": "pikita",
//     "disable": true,
//     "title": "Pikita",
//     "short": "pikita",
//     "category": ["Board Game"],
//     "duration": "20 minutes",
//     "minPlayers": 2,
//     "maxPlayers": 8,
//     "summary": "A set of simple and fun games.",
//     "icon": "https://cdn.siter.io/assets/ast_DunMFnKHyjjqkJHKyraQz59vo/ffde5af2-bb5e-40a7-8ccb-bba6d9c8f144.png",
//     "screen_url": "https://unboared-unblind-test.web.app",
//     "gamepad_url": "https://unboared-unblind-test.web.app/gamepad",
// },
// {
//     "gameID": "loups-garous",
//     "disable": true,
//     "title": "Loups-Garous !",
//     "short": "loups-garous",
//     "category": ["Board Game"],
//     "duration": "20 minutes",
//     "minPlayers": 4,
//     "maxPlayers": 12,
//     "summary": "In the 'Wild East', the small hamlet of Thiercelieux has recently fallen prey to Werewolves. Murders are committed every night by some of the villagers, who have become Lycanthropes due to a mysterious phenomenon (perhaps the greenhouse effect?)... The villagers must pull together to eradicate this new scourge from the depths of time, before the hamlet loses its last inhabitants.",
//     "icon": "https://cdn.siter.io/assets/ast_5NvYiQYp8YwvxJ3CiUcxSPFmU/270a1e23-d440-47b3-b10a-4ebd684a1259.jpeg",
//     "screen_url": "https://unboared-unblind-test.web.app",
//     "gamepad_url": "https://unboared-unblind-test.web.app/gamepad",
// },
// {
//     "gameID": "party-challenges",
//     "disable": true,
//     "title": "Party Challenges",
//     "short": "party-challenges",
//     "category": ["Board Game"],
//     "duration": "20 minutes",
//     "minPlayers": 2,
//     "maxPlayers": 12,
//     "summary": "A hilarious party game that will take your parties to the next level. In teams or one by one, answer the questions and follow the rules, no matter how wild and crazy they get! Whether you're at the bar or hanging out at home.",
//     "icon": "https://cdn.siter.io/assets/ast_hY5Kn6krPTTeBfitBLNT4hDNm/d91a1e45-8711-4df8-af14-e2e5137caa94.jpg",
//     "screen_url": "https://unboared-unblind-test.web.app",
//     "gamepad_url": "https://unboared-unblind-test.web.app/gamepad",
// }]
