import React, { useEffect } from 'react';

import { UnboaredGame, CommunicationConfig } from '@unboared/lib';
import { useGamepadScale } from '@unboared/base-ui.all';

import { DEFAULT_SCENE, GAME_NAME, getSocketServer } from '~/config/const';
import { useActiveUser, userConverter } from '~/services/user';
import { UnboaredPageLoader } from '~/components/common/loaders';
import { GamepadPlatformPage } from '~/screens/gamepad/platform';
import { useParams } from 'react-router-dom';
import { logAnalyticsEvent, useAnalyticsAccessEvent } from '~/services/firebase';
import { useAuth, getAuthType } from '~/services/auth';

export const UnboaredGamepadApp = () => {
  const { auth } = useAuth();
  useAnalyticsAccessEvent('platform/gamepad');
  useEffect(() => {
    if (auth) {
      logAnalyticsEvent(`platform/gamepad/auth/${getAuthType(auth)}`);
    }
  }, [auth]);
  return <UnboaredGamepadAppContent />;
};

export const UnboaredGamepadAppContent = () => {
  useGamepadScale();
  const { roomID } = useParams();

  const user = useActiveUser((state) => state.user);

  if (!user) {
    return <UnboaredPageLoader infos={`Authentification ...`} />;
  }

  return (
    <UnboaredGame
      name={GAME_NAME}
      initialScene={DEFAULT_SCENE}
      loader={<UnboaredPageLoader infos="Connexion to the server..." />}
      config={CommunicationConfig.getSocketIO(
        'gamepad',
        getSocketServer(),
        { id: roomID },
        userConverter.toPlayer(user),
      )}
    >
      <GamepadPlatformPage />
    </UnboaredGame>
  );
};