import IconN64 from '~/assets/img/retro/Icon_N64.png'
import CoverN64 from '~/assets/img/retro/Cover_N64.png'

import IconSNES from '~/assets/img/retro/Icon_SNES.png'
import CoverSNES from '~/assets/img/retro/Cover_SNES.png'

import IconAtari7800 from '~/assets/img/retro/Icon_Atari7800.png'
import CoverAtari7800 from '~/assets/img/retro/Cover_Atari7800.png'


export default { IconAtari7800, CoverAtari7800, IconN64, CoverN64, IconSNES, CoverSNES}