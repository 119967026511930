import React from 'react';
import { MuteButton as MuteButtonComponent } from '@unboared/design.all';
import { useMusicManager } from '@unboared/utils.sound';
import { api } from '@unboared/lib';

export const MuteButton = ({ size = 40 }: { size?: number }) => {
  const volume = useMusicManager((state) => state.volume);
  const toggleMute = useMusicManager((state) => state.toggleMute);

  const pressMute = () => {
    toggleMute();
    api().mute(volume > 0);
  };

  return (
    <MuteButtonComponent size={size} onPress={pressMute} active={volume === 0} />
  );
};
