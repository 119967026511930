import { useEffect, useState } from 'react';

import { createGlobalStorage } from '@unboared/utils.storage';
import { usersAPI } from '~/services/users_api/users_api';
import {
  createDefaultUser,
  initialUser,
  initialUserState,
  userConverter,
  UserState,
} from './user_state';
import { Player } from '@unboared/native.types';
import { useAuth } from '~/services/auth';

/* The store that keep informations about the authentification */
export const useActiveUser = createGlobalStorage<UserState>((set, get) => ({
  ...initialUserState,

  /**
   *
   */
  createUser: (uid: string, player: Player) => {
    const newUser = {
      ...initialUser,
      ...userConverter.fromPlayer(player),
      uid: uid,
    };
    set((state: UserState) => ({ ...state, loading: true, errorMessage: '' })); // start loading
    usersAPI
      .createUser(newUser)
      .then(() => {
        set((state: UserState) => ({
          ...state,
          loading: false,
          user: newUser,
        }));
      })
      .catch((error) => {
        set((state: UserState) => ({
          ...state,
          loading: false,
          errorMessage: error,
        })); // start loading
      });
  },

  /**
   *
   * @param user
   * @returns
   */
  loadUserData: (uid: string) => {
    set((state: UserState) => ({ ...state, loading: true, errorMessage: '' })); // start loading
    if (uid) {
      usersAPI
        .getUser(uid)
        .then((user) => {
          set((state: UserState) => ({ ...state, loading: false, user: user })); // user found in database
        })
        .catch((error) => {
          set((state: UserState) => ({
            ...state,
            loading: false,
            errorMessage: error,
          })); // user not found in database
        });
    } else {
      set((state: UserState) => ({
        ...state,
        loading: false,
        errorMessage: '',
      }));
    }
  },

  /**
   * Set the authentified user.
   */
  setUser: (user: any) => set({ user: user }),

  /**
   * Set the user for guest.
   */
  setDefaultUser: () =>
    set((state: UserState) => ({
      ...state,
      loading: false,
      user: createDefaultUser(),
    })),
}));

/**
 * Hook that can be used to get the authentified user.
 *
 * @returns the current user
 */
export const useActiveUserManager = () => {
  const { auth } = useAuth();
  const loadUserData = useActiveUser((state) => state.loadUserData);
  useEffect(() => {
    if (auth && auth.uid) {
      loadUserData(auth.uid);
      // api().loadCustomDeviceStateProperty(api().getDeviceID(), 'subscription', getSubscriptionMinified(auth));
    }
  }, [auth]);
};

export const getSubscriptionMinified = (auth: any) => {
  const { isLogin, is } = auth;

  return {};
};
