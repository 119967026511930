import React, { useState } from 'react';
import { View, StyleSheet, ImageBackground } from 'react-native';
import styled from 'rn-css';

import {
  Text,
  Heading,
  accent_color,
  Button,
  unboaredTheme,
  ThemeProvider,
  TextInput,
  useTranslate,
  TextInputWithButton,
} from '@unboared/base-ui.all';

import { Logo } from '@unboared/design.all';
import bgImg from '~/assets/img/access-bg-sm.png';
import { useNormalize } from '@unboared/base-ui.responsive.sizes';
import { space } from '@unboared/base-ui.theme.spacing';
import { useScreenInfos } from '@unboared/utils.orientation';
import { ACCESS_TOKEN, ACCESS_VERIFICATION } from '~/config/const';
import { useToken } from '~/utils/access_verification';

export type LaunchPageProps = {
  onLaunch: () => void;
  props?: any;
};

export const LaunchPage = ({ onLaunch, ...props }: LaunchPageProps) => {
  const { normalize } = useNormalize();
  const { translate } = useTranslate();
  const screenInfos = useScreenInfos();

  const [code, setCode] = useState('');
  const [error, setError] = useState(false);

  const { token, setToken } = useToken();

  const handleSubmit = () => {
    if (token === ACCESS_TOKEN) {
      onLaunch();
    } else {
      if (code === ACCESS_TOKEN) {
        setToken(code);
        onLaunch();
      } else {
        setError(true);
      }
    }
  };

  let contentViewStyle;
  switch (screenInfos.type) {
    case 'laptop':
      contentViewStyle = {
        margin: normalize(space.massive),
        width: normalize(350),
        height: normalize(160),
      };
      break;

    default:
      contentViewStyle = {
        margin: normalize(space.larger),
        width: normalize(300),
        height: normalize(160),
      };
      break;
  }

  return (
    <ThemeProvider theme={unboaredTheme.dark}>
      <View style={styles.container}>
        <ImageBackground
          resizeMode="cover"
          style={styles.background}
          source={bgImg}
        >
          <ContentView {...contentViewStyle}>
            <Logo size={30} />
            <Heading tx="screen.accessPage.title" />
            <Text tx="screen.common.description" />

            {ACCESS_VERIFICATION && token !== ACCESS_TOKEN && (
              <Heading type="h3">
                {translate('screen.launchPage.launchTitle')}
              </Heading>
            )}
            {error ? (
              <Text style={{ color: accent_color.error }}>
                ⚠️ {translate('screen.launchPage.wrongAccessCode')}{' '}
              </Text>
            ) : (
              <></>
            )}
            {ACCESS_VERIFICATION && token !== ACCESS_TOKEN ? (
              <TextInputWithButton
                text={code}
                style={{ fontSize: normalize(10) }}
                placeholder={translate(
                  'screen.accessPage.codeInput.placeholder',
                )}
                onChangeText={setCode}
                submitText={translate('screen.launchPage.launchBtn')}
                onSubmit={handleSubmit}
                size={10}
              />
            ) : (
              <Button
                textPreset="medium"
                tx="screen.launchPage.launchBtn"
                onPress={handleSubmit}
                style={{ width: 0.3 * contentViewStyle.width }}
              />
            )}
          </ContentView>
        </ImageBackground>
      </View>
    </ThemeProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  important: {
    color: accent_color.important,
  },
});

const ContentView = styled.View<{
  margin: number;
  width: number;
  height: number;
}>`
  margin-horizontal: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  justify-content: space-between;
`;
