import { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';

import { analytics } from './firebase';
import { isProdMode } from '~/config/env';
import { PLAY_UNBOARED_DOMAIN } from '~/config/const';

export const logAnalyticsEvent = (eventName: string, data = {}) => {
  if (isProdMode() && window.location.hostname.includes(PLAY_UNBOARED_DOMAIN)) {
    logEvent(analytics, `retro/${eventName}`, data);
  } else {
    console.log(`[ANALYTICS] logEvent - ${eventName}`);
  }
};

export const useAnalyticsAccessEvent = (eventName: string, data = {}) => {
  useEffect(() => {
    logAnalyticsEvent(eventName, data);
    // eslint-disable-next-line
  }, []);
};
