import React, { useEffect } from 'react';
import { View } from 'react-native';

import { useScene } from '@unboared/lib';

import { space, useTheme, useNormalize } from '@unboared/base-ui.all';
import { useScreenInfos } from '@unboared/utils.orientation';

/* Import debug utils */
import { log } from '~/config/env';

/* Import screen scenes */
import { ScreenHomePage } from '~/screens/scenario/home/screen';
import { ScreenSearchPage } from '~/screens/scenario/search/screen';
import { ScreenSettingsPage } from '~/screens/scenario/settings/screen';
import { ScreenInGamePage } from '~/screens/scenario/in_game/screen';
import { Screen404Page } from '~/screens/scenario/404/screen';

import { ChangeScreenOrientationPage } from '~/screens/common/change_screen_orientation';

/* Import screen layouts for the scenes */
import { InGameLayout } from '~/components/screen/layout/in_game';
import { InPlatformLayout } from '~/components/screen/layout/in_platform';

/* Import system actions and music player */
import {
  Music,
  useMusicManager,
  useMusicListener,
} from '~/components/screen/view/music_player';

import { GlobalPopup } from '~/components/screen/popup/global_popup';
import { ConnexionInfosPopup } from '~/components/screen/popup/connexion_infos';
import { LaunchSystemPopup } from '~/components/screen/popup/launch_system';

/* Import navigation utils */
import { Navigate, Route, Routes } from '~/navigation/router';
import { Navbar } from '~/components/screen/navbar';

export const ScreenPlatformApp = () => {
  log.debug('In PlatformApp');
  const { scene } = useScene();
  const theme = useTheme();
  useMusicListener();

  // useListener("pressFullscreen", () => toggleFullscreen(document.documentElement));

  const { isPortrait } = useScreenInfos();

  if (isPortrait) {
    return <ChangeScreenOrientationPage to="landscape" />;
  }

  return (
    <View style={{ flex: 1, backgroundColor: theme.backgroundColor }}>
      <Music />
      <GlobalPopup />
      <ConnexionInfosPopup />
      <LaunchSystemPopup />
      {scene !== 'in_game' ? <ScreenInPlatformApp /> : <ScreenInGameApp />}
    </View>
  );
};

export const ScreenInGameApp = () => {
  return (
    <InGameLayout>
      <ScreenRoutes />
    </InGameLayout>
  );
};

export const ScreenInPlatformApp = () => {
  const { normalize } = useNormalize();

  const { scene } = useScene();
  const { onPlay } = useMusicManager();

  useEffect(() => {
    onPlay();
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <Navbar
        active={scene}
        style={{ bottom: normalize(space.small), left: '40%' }}
      />
      <InPlatformLayout>
        <ScreenRoutes />
      </InPlatformLayout>
    </View>
  );
};

const ScreenRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ScreenHomePage />} />
      <Route path="search" element={<ScreenSearchPage />} />
      <Route path="settings" element={<ScreenSettingsPage />} />
      <Route path="game">
        <Route path=":game" element={<ScreenInGamePage />} />
      </Route>
      <Route path="rom">
        <Route path=":rom" element={<ScreenInGamePage rom/>} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
