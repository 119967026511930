import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db as firebaseDB } from '~/services/firebase';

export const addRomData = async (uid: string, emulator: string, id: string, url: string) => {
    const docRef = doc(firebaseDB, 'users', uid);

    const data = {
        romURL: url,
        emulator: emulator,
        id: id,
        infos: {
            title: id,
        }
    }

    updateDoc(docRef, {
        uploadGames: arrayUnion(data)
    })
}

// export const updateRomInfos = (uid: string, ) => {
//     const docRef = doc(firebaseDB, 'users', uid);

//     const data = {
//         romURL: url,
//         emulator: emulator,
//         id: id,
//         infos: {
//             title: id,
//         }
//     }

//     updateDoc(docRef, {
//         uploadGames: arrayUnion(data)
//     })
//         .then(() => {
//             console.log('[SUCCESS] Success to add rom data');
//         })
//         .catch(() => {
//             console.error('[FAIL] Failed to add rom data');
//         });

// }