import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useLinkTo, useParams } from '~/navigation/router';

import {
  api,
  usePlayers,
  useScene,
  useCustomDeviceStateProperty,
} from '@unboared/lib';

import { useTheme } from '@unboared/utils.theme';
import { useMusicManager } from '@unboared/utils.sound';

import { GameRunner } from '~/components/screen/view/game_runner';
import { useGame } from '~/services/games_api/games_api';
import { UnboaredPageLoader } from '~/components/common/loaders';
import { PauseMenuPopup } from '~/components/screen/popup/pause_menu';
import { log, URL_TEST_GAME_SCREEN } from '~/config/env';
import { useRom } from '~/services/roms_api';

export const GameScreen = ({ game }) => {
  const linkTo = useLinkTo();

  useEffect(() => {
    if (!game.screen_url) {
      linkTo('/');
    }
  }, []);

  return (
    <GameRunner
      title={game.id || 'Game'}
      src={game.screen_url}
      version={game.ub_version || '0.3'}
    />
  );
};

export const ScreenInGamePage = ({ rom }: { rom?: boolean }) => {
  log.debug('In PlatformApp [InGame]');

  const onPause = useMusicManager((state) => state.onPause);
  // const { width, height } = useScreenInfos()

  const { loadCustomDeviceStateProperty } = useCustomDeviceStateProperty();

  const theme = useTheme((state) => state.theme);
  const params = useParams();
  const gameID = params['*']?.split('/')[1];
  const game = rom ? useRom(gameID) : useGame(gameID);

  const { loadScene } = useScene();
  useEffect(() => {
    console.log(game);
    loadCustomDeviceStateProperty(api().getDeviceID(), 'currentGame', game);
    loadScene('in_game');
  }, [game]);

  useEffect(() => {
    onPause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!game) {
    return <UnboaredPageLoader infos="Loading game" />;
  }

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.backgroundColor,
      }}
    >
      <PauseMenuPopup game={game} />
      <GameScreen game={game} />
    </View>
  );
};
