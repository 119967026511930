import React, { useRef, useState } from 'react';
import { View, StyleSheet, Pressable, TextStyle } from 'react-native';

import {
  useTranslate,
  useNormalize,
  Heading,
  Text,
  size,
  transparency,
  useTheme,
  space,
  base_color,
  TextInput,
  accent_color,
} from '@unboared/base-ui.all';
import { useActiveUser } from '~/services/user';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { EMULATORS, getEmulator } from './const';
import { UploadButton } from './upload_button';
import { addRomData } from './rom_data';
import { useGlobalPopup } from '~/components/screen/popup/global_popup';
import { EditRom } from './edit_rom';

export type UploadROMProps = {
  /**
   * what to do when closing is called.
   */
  onClose: () => void;
};

export function UploadROM({ onClose }: UploadROMProps) {
  const theme = useTheme();

  const uploadTaskRef = useRef<any>();

  const [id, setID] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [romErrorMessage, setRomErrorMessage] = useState('');
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const [uploadStatus, setUploadStatus] = useState<any>();
  const { user } = useActiveUser();
  const { open } = useGlobalPopup();

  const { normalize } = useNormalize();

  const [isHover, setIsHover] = useState(false);

  const disableUpload = !selectedFile || !id;

  function handleChange(event: any) {
    const emulator = getEmulator(event.target.files[0].name.split('.').pop());
    if (emulator && EMULATORS.includes(emulator)) {
      setSelectedFile(event.target.files[0]);
      setID(event.target.files[0].name.split('.').shift());
      setRomErrorMessage('');
    } else {
      setSelectedFile(null);
      setID('');
      setRomErrorMessage(
        `Type de fichier non reconnu - ${event.target.files[0].name}`,
      );
    }
  }

  function handleSubmit() {
    if (selectedFile && id) {
      const ext = selectedFile.name.split('.').pop();
      const emulator = getEmulator(ext);
      if (emulator && EMULATORS.includes(emulator)) {
        console.log('UPLOAD ROM');
        const storage = getStorage();
        const filePath = `users/${user.uid}/emulation/roms/${emulator}/${id}.${ext}`;
        const storageRef = ref(storage, filePath);

        // 'file' comes from the Blob or File API
        let uploadTask = uploadBytesResumable(storageRef, selectedFile);

        uploadTaskRef.current = uploadTask;

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            );
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                setUploadStatus({ status: 'paused', progress: progress });
                break;
              case 'running':
                console.log('Upload is running');
                setUploadStatus({ status: 'running', progress: progress });
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                setUploadErrorMessage('Accès au stockage non autorisé.');
                break;
              case 'storage/canceled':
                // User canceled the upload
                setUploadErrorMessage('Importation annulée.');
                setUploadStatus(undefined);
                break;

              // ...

              case 'storage/unknown':
                setUploadErrorMessage(
                  error.serverResponse || "Erreur d'importation",
                );
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
            uploadTaskRef.current = undefined;
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUploadStatus(undefined);
              setUploadErrorMessage('');
              uploadTaskRef.current = undefined;
              addRomData(user.uid, emulator, id, downloadURL)
                .then(() => {
                  open(
                    <EditRom
                      romInfos={{ id:id, title: id, minPlayers: 1 }}
                      onClose={onClose}
                    />,
                  );
                })
                .catch(() => {
                  setUploadErrorMessage('Erreur de création de données');
                });
            });
          },
        );
      }
    }
  }

  return (
    <View style={{ justifyContent: 'center' }}>
      <Heading text={`Ajouter une ROM`} />
      <View
        style={{
          marginVertical: normalize(space.small),
        }}
      >
        <Pressable
          style={{
            marginVertical: normalize(space.small),
            padding: normalize(space.medium),
            justifyContent: 'center',
            height: normalize(150),
            width: normalize(400),
            maxWidth: '100%',
            maxHeight: '100%',
            backgroundColor: transparency('dark', 0.1),
            borderRadius: normalize(size.borderRadius.small),
            borderWidth: normalize(size.borderWidth.medium),
            borderColor: isHover ? theme.color.primary : theme.backgroundColor,
          }}
          onHoverIn={() => {
            setIsHover(true);
          }}
          onHoverOut={() => {
            setIsHover(false);
          }}
        >
          <input style={styles.input} type="file" onChange={handleChange} />
          <Text>Déposez votre ROM ou cliquez ici</Text>
          <HelpMessage
            currentEmulator={
              selectedFile
                ? getEmulator(selectedFile.name.split('.').pop())
                : ''
            }
          />
          <Text
            style={
              {
                alignSelf: 'center',
                marginTop: normalize(space.smaller),
                color: romErrorMessage
                  ? accent_color.warning
                  : accent_color.success,
              } as TextStyle
            }
          >
            {selectedFile ? selectedFile.name : ''}
            {romErrorMessage || ''}
          </Text>

          {uploadErrorMessage && (
            <Text
              text={uploadErrorMessage}
              style={{
                marginTop: normalize(space.smaller),
                color: accent_color.warning,
              }}
            />
          )}
        </Pressable>
        {selectedFile && (
          <TextInput
            label="Identifiant unique *"
            text={id}
            onChangeText={setID}
            placeholder="Identifiant du jeu"
            helperText="* Cet identifiant servira à identifier le jeu de façon unique sur le serveur."
          />
        )}
      </View>

      <UploadButton
        uploadTask={uploadTaskRef}
        uploadStatus={uploadStatus}
        handleSubmit={handleSubmit}
        disable={disableUpload}
        onClose={onClose}
      />
    </View>
  );
}

const HelpMessage = ({ currentEmulator }: any) => {
  const { normalize } = useNormalize();

  console.log(currentEmulator);

  const getStyle = (emulator: string) => ({
    fontSize: normalize(7),
    color:
      /* 
      currentEmulator == emulator
        ? accent_color.important
        : */ base_color.light_tertiary,
    fontWeight: currentEmulator == emulator ? 700 : 400,
  });

  return (
    <>
      <Text
        style={{
          marginTop: normalize(space.tiny),
          fontSize: normalize(7),
          color: base_color.light_tertiary,
        }}
      >
        Fichiers valides:
      </Text>
      <Text style={getStyle('atari78')}>{`   `}- Atari 7800 (.a78)</Text>
      {/* <Text style={getStyle('nes')}>
        {`   `}- Nintendo Entertainement System (.fds, .nes, .unif, .unf)
      </Text> */}
      <Text style={getStyle('snes')}>
        {`   `}- Super Nintendo Entertainement System (.smc, .sfc, .fig, .gd3,
        .gd7, .dx2, .bsx, .swc)
      </Text>
      <Text style={getStyle('n64')}>{`   `}- Nintendo 64 (.n64, .z64)</Text>
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    cursor: 'pointer',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    color: '#FFF',
  },
});
