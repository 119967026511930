import React from "react"
import { ViewStyle } from "react-native"

import { Branding } from "~/components/common/branding"
import { DarkImageContainer } from "~/components/common/layout/dark_image_container"

export const AuthContainer = ({ children }: any) => {
    const styles = {
        container: {
            flexDirection: "row",
        } as ViewStyle,
        flexCenter: {
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
        } as ViewStyle
    }

    return (
        <DarkImageContainer style={[styles.flexCenter, styles.container]} noBackground>
            <Branding />
            {children}
        </DarkImageContainer>
    )
}
