import { accent_color, Button, Heading, space, Text, useNormalize, useTranslate } from "@unboared/base-ui.all"
import { View } from "react-native"
import { useGlobalPopup } from "~/components/screen/popup/global_popup"
import { useAuth } from "~/services/auth"
import { ActivityIndicator } from "~/components/common/loaders"
import { Reauthenticate } from "./reauthenticate"
import { PasswordInput } from "~/screens/common/signin"
import { useEffect, useState } from "react"


export const ChangePasswordConfirmation = () => {
    const { normalize } = useNormalize()
    const { translate } = useTranslate()

    const [password, setPassword] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const { auth, loading, updatePassword, errorMessage, changePassword, passwordErrorMessage, reauthenticating } = useAuth()

    const close = useGlobalPopup(state => state.close)

    const updateExternalPassword = () => {
        updatePassword(password)
            .then(() => {
                setSuccessMessage("Successfully update password")
            })
            .catch(() => {
                console.log("Error when updating password")
            })
    }

    const setPasswordAndHideError = (text: string) => {
        changePassword('')
        setPassword(text)
    }

    if (loading) {
        return <ActivityIndicator />
    }

    if (reauthenticating) {
        return <Reauthenticate
            txAction="screen.settingPage.myAccount.action.updatePassword.popup.validate"
            onAuthenticated={updateExternalPassword}
        />
    }

    return (
        <>
            <Heading type='h2' tx="screen.settingPage.myAccount.action.updatePassword.popup.title" />
            <Text style={{ marginVertical: normalize(space.tiny) }} text={translate("screen.settingPage.myAccount.action.updatePassword.popup.description", { email: auth.email })} />
            {errorMessage && <Text style={{ marginVertical: normalize(space.tiny), color: accent_color.warning }} text={translate(errorMessage)} />}
            {successMessage && <Text style={{ marginVertical: normalize(space.tiny), color: accent_color.success }} text={successMessage} />}
            {
                !successMessage &&
                <PasswordInput
                    label={translate("screen.settingPage.myAccount.action.updatePassword.popup.input.label")}
                    placeholder={translate("screen.settingPage.myAccount.action.updatePassword.popup.input.placeholder")}
                    password={password}
                    onChangeValue={setPasswordAndHideError}
                    passwordErrorMessage={translate(passwordErrorMessage)}
                />
            }
            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                {
                    !successMessage &&
                    <Button tx="screen.settingPage.myAccount.action.updatePassword.popup.validate" onPress={updateExternalPassword} />
                }
                <Button tx="screen.settingPage.myAccount.action.updatePassword.popup.cancel" onPress={close} />
            </View>
        </>
    )
}
