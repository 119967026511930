import React, { useState } from 'react';
import {
  Button,
  Heading,
  Text,
  TextInput,
  accent_color,
  space,
  useNormalize,
} from '@unboared/base-ui.all';
import { View } from 'react-native';
import { useActiveUser } from '~/services/user';
import { romsAPI } from '~/services/roms_api';

export const EditRom = ({ romInfos, onClose }: any) => {
  const { normalize } = useNormalize();
  const { user } = useActiveUser();

  console.log('IN EDIT', romInfos);

  const [title, setTitle] = useState(romInfos?.title || '');
  const [summary, setSummary] = useState(romInfos?.summary || '');
  const [minPlayers, setMinPlayers] = useState(romInfos?.minPlayers || 1);
  const [maxPlayers, setMaxPlayers] = useState(romInfos?.maxPlayers || 4);
  const [duration, setDuration] = useState(romInfos?.duration || '');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = () => {
    romsAPI
      .updateGameInfos(user.uid, romInfos.id, {
        title,
        summary,
        minPlayers,
        maxPlayers,
        duration,
      })
      .then(onClose, (e) => {
        console.log(e)
        setErrorMessage("Les données n'ont pas pu être sauvegardées.");
      });
  };

  return (
    <View style={{ justifyContent: 'center' }}>
      <Heading text={`Editer les informations de la ROM`} />
      <View
        style={{
          marginVertical: normalize(space.small),
        }}
      >
        <TextInput label="Title" text={title} onChangeText={setTitle} />
        <TextInput
          label="Duration"
          text={duration}
          onChangeText={setDuration}
        />
        <TextInput
          style={{ height: normalize(100) }}
          label="Summary"
          text={summary}
          onChangeText={setSummary}
        />
        {/* <TextInput label='Title' value={title} onChangeText={setTitle} /> */}

        {errorMessage && (
          <Text text={errorMessage} style={{ color: accent_color.warning }} />
        )}
      </View>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <Button
          style={{ marginRight: normalize(space.smaller) }}
          preset="secondary"
          text="Annuler"
          onPress={onClose}
        />
        <Button
          style={{ flex: 1 }}
          icon="md-save-alt"
          text="Sauvegarder"
          onPress={handleSubmit}
        />
      </View>{' '}
    </View>
  );
};
